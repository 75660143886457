import { isSessionStorageEnabled } from 'utils/generalUtils';
import isBrowser from 'utils/isBrowser';
import DevController from '../DevController';
import {
  mockMPQuestionOptions,
  mockGetMPAdditionalSchoolListings,
  mockGetMPTcpaListings,
} from './mockFunctions';
/**
 * An endpoint's override config
 * @typedef {isActive: boolean, mockFunction: function(*)} EndpointOverrideConfig
 */

/**
 * A Map of endpoints to override configs
 * @type {Object.<string, EndpointOverrideConfig>} ApiOverridesMap
 */
const API_OVERRIDE_MAP = new Map([
  [
    '/api/triadms/microportal/mpQuestionOption',
    {
      label: 'MP Get Options',
      mockFunction: mockMPQuestionOptions,
      isActive: false,
    },
  ],
  [
    '/api/triadms/userProfile',
    {
      label: 'Get User Profile',
      mockFunction: () => {},
      isActive: false,
    },
  ],
  [
    '/api/triadms/microportal/GetMPAdditionalSchoolListings',
    {
      label: 'MP Get School Listings',
      mockFunction: mockGetMPAdditionalSchoolListings,
      isActive: false,
    },
  ],
  [
    '/api/triadms/microportal/GetMPTcpaListings',
    {
      label: 'MP Get TCPA',
      mockFunction: mockGetMPTcpaListings,
      isActive: false,
    },
  ],
]);

let currentDevAPIOverrideController = null;

class DevAPIOverrideController extends DevController {
  constructor() {
    super();
    if (!isSessionStorageEnabled()) {
      return;
    }
    /**
     * @type {API_OVERRIDE_MAP}
     * */
    this._apiOverridesMap = new Map(
      this.isActive ? this._getApiOverridesFromLocalStorageOrInitialize() : []
    );

    currentDevAPIOverrideController = this;
  }

  /**
   * @param {String} endpoint
   * @returns {boolean}
   */
  isApiOverride(endpoint) {
    return (
      this._apiOverridesMap.has(endpoint) &&
      this._apiOverridesMap.get(endpoint).isActive
    );
  }

  /**
   * @param {String} endpoint
   * @param {Object} payload
   * @returns {Promise}
   */
  callApiOverride(endpoint, payload) {
    if (!this.isApiOverride(endpoint)) {
      return Promise.reject(new Error(`${endpoint} is not an active override`));
    }

    return this._apiOverridesMap.get(endpoint).mockFunction(payload);
  }

  /**
   * @returns {!API_OVERRIDE_MAP}
   */
  getApiOverrides() {
    return this._apiOverridesMap;
  }

  /**
   * @param {String} endpoint
   * @param {boolean} isActive
   */
  setApiOverrideActive(endpoint, isActive) {
    if (this._apiOverridesMap.has(endpoint)) {
      const apiConfigs = this._apiOverridesMap.get(endpoint);
      apiConfigs.isActive = isActive;
      this._apiOverridesMap.set(endpoint, apiConfigs);
      this._storeApiOverridesLocally();
    }
  }

  /**
   * @private
   */
  _storeApiOverridesLocally() {
    this.setSessionStorageValue(
      'api_overrides',
      JSON.stringify(Array.from(this._apiOverridesMap.entries()))
    );
  }

  /**
   * @private
   */
  _getApiOverridesFromLocalStorageOrInitialize() {
    const localChanges = JSON.parse(
      this.getSessionStorageValue('api_overrides')
    );

    if (this._hasValidLocalStorageApiOverrideValues()) {
      localChanges.forEach((entry) => {
        const [endpoint, config] = entry;
        config.mockFunction = API_OVERRIDE_MAP.get(endpoint).mockFunction;
      });
      return localChanges;
    }

    return API_OVERRIDE_MAP;
  }

  /**
   * @private
   * @returns {boolean} true if local storage is note state. False otherwise
   */
  _hasValidLocalStorageApiOverrideValues() {
    const localChanges = JSON.parse(
      this.getSessionStorageValue('api_overrides')
    );
    let isLocalStorageUpToDate = !!localChanges;

    if (localChanges) {
      const localChangesMap = new Map(localChanges);
      [...API_OVERRIDE_MAP].forEach((entry) => {
        const [endpoint] = entry;

        if (!localChangesMap.has(endpoint)) {
          isLocalStorageUpToDate = false;
        }
      });

      [...localChangesMap].forEach((entry) => {
        const [endpoint] = entry;
        if (!API_OVERRIDE_MAP.has(endpoint)) {
          isLocalStorageUpToDate = false;
        }
      });

      if (!isLocalStorageUpToDate) {
        // eslint-disable-next-line no-alert
        alert(
          'Stale local storage values for API Dev Container. Reloading and Clearing configs'
        );
        sessionStorage.clear();
        window.location.reload();
      }

      return isLocalStorageUpToDate;
    }

    return isLocalStorageUpToDate;
  }
}

/**
 * @returns {DevContainerController}
 */
export default function getDevAPIOverrideController() {
  if (!isSessionStorageEnabled() && isBrowser()) {
    console.error(
      'SessionStorage is not available. Cannot create DevAPIOverrideController.'
    );
    currentDevAPIOverrideController = {};
  }
  return currentDevAPIOverrideController || new DevAPIOverrideController();
}
