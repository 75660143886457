import { AVAILABLE_COMPONENTS } from 'components/AVAILABLE_COMPONENTS';
import triadToPropsTransform from 'components/form-wizards/basic-form/triadToPropsTransform';
import wpToBillBoardTransform from 'components/sections/billBoard/wpToBillBoardTransform';
import wpToUserContentTransform from 'components/sections/userContent/wpToUserContentTransform';
import wpToHeroPropsTransform from 'components/sections/heros/wpToHeroPropsTransform';
import wpToFeatureBlocksTransform from 'components/sections/featureBlocks/wpToFeatureBlocksTransform';
import wpToProgramInfoTransform from 'components/sections/programInfo/wpToProgramInfoTransform';
import wpToSliderTransform from 'components/sections/slider/wpToSliderTransform';
import wpToProgramSearchTransform from 'components/sections/program-selection/pagedProgramsSearch/wpToProgramSearchTransform';
import { LogError } from 'utils/logging';
import wpToAdmissionsTransform from 'components/sections/admissions/wpToAdmissionsTransform';
import wpToFaqPropsTransform from 'components/sections/faq/wpToFaqPropsTransform';

const TRANSFORM_MAP = {
  [AVAILABLE_COMPONENTS.PROGRAM_SEARCH]: wpToProgramSearchTransform,
  [AVAILABLE_COMPONENTS.FEATURE_BLOCKS]: wpToFeatureBlocksTransform,
  [AVAILABLE_COMPONENTS.BASIC_FORM_WIZARD]: triadToPropsTransform,
  [AVAILABLE_COMPONENTS.MULTI_QUESTION_FORM]: triadToPropsTransform,
  [AVAILABLE_COMPONENTS.MULTI_QUESTION_FORM_FULL_PAGE]: triadToPropsTransform,
  [AVAILABLE_COMPONENTS.CLICK_PORTAL_SIDEBAR_FORM_WIZARD]:
    triadToPropsTransform,
  [AVAILABLE_COMPONENTS.CLICK_PORTAL_FORM_WIZARD]: triadToPropsTransform,
  [AVAILABLE_COMPONENTS.MICRO_PORTAL_FORM_WIZARD]: triadToPropsTransform,
  [AVAILABLE_COMPONENTS.FULL_PAGE_FORM_WIZARD]: triadToPropsTransform,
  [AVAILABLE_COMPONENTS.BILLBOARD]: wpToBillBoardTransform,
  [AVAILABLE_COMPONENTS.ADMISSIONS]: wpToAdmissionsTransform,
  [AVAILABLE_COMPONENTS.USER_CONTENT]: wpToUserContentTransform,
  [AVAILABLE_COMPONENTS.PAGE_HERO_V1]: wpToHeroPropsTransform,
  [AVAILABLE_COMPONENTS.PROGRAM_INFO]: wpToProgramInfoTransform,
  [AVAILABLE_COMPONENTS.SLIDER]: wpToSliderTransform,
  [AVAILABLE_COMPONENTS.FAQ]: wpToFaqPropsTransform,
};

/**
 * @summary This is one of the most important functions. This is only ran on the server in
 *          getLayouts.js. It will run over all page sections mapping a component transform
 *          function to the section so that we can map props to components using many data sources
 * @param {Object} response - The response from WordPress or some backend API.
 *                            If wordpress this will most likely be a page section
 * @param {Object} metaData.pageLayoutInfo - the full response from the API
 * @param {Object} metaData.programMap - the response from programMap. Should be a map of programs
 * @param {Object} metaData.urlQuery - all url params. Note dynamic uri parts are in this map as well
 * @param {Object} metaData.taxonomyMap - degrees, parent categories, and all programs for this micro site
 */
export default function responsePropsTransform(response, metaData) {
  const component = response.acf_fc_layout || response.component;
  const propsTransformFunc = TRANSFORM_MAP[component];

  if (typeof propsTransformFunc !== 'function') {
    LogError(
      `responsePropsTransform Error: component "${component}" is not supported`
    );

    return { component };
  }

  try {
    const componentProps = propsTransformFunc(response, metaData);

    return {
      ...componentProps,
      component,
      useLandingPageHeadline: response.useLandingPageHeadline || false,
    };
  } catch (error) {
    LogError(
      `responsePropsTransform Error: component "${component}" had bad data`,
      { error: error.message }
    );
    return {};
  }
}
