import get from 'lodash/get';

/**
 * @summary its possible some form questions are answered and must be skipped over when user gets to them
 * @param {Array} questions - an array of questions
 * @param {Object} formState - the formState that useBasicFormWizardHandler produces
 */
export default function getVisibleQuestions(questions, formState) {
  return questions.filter(
    ({ name }) =>
      !get(formState, `formValues[${name}].shouldSkip`) &&
      !get(formState, `formValues[${name}][0].shouldSkip`) &&
      !get(
        formState,
        `formStatus.linkedSessionFormValues[${name}].isFromOtherSite`
      )
  );
}
