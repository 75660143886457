import get from 'lodash/get';
import superagent from 'superagent';
import { TRIADMS_BACKEND, TRIAD_PROXY_ROUTE } from 'app-requests/apiConstants';
import getDevAPIOverrideController from 'components/dev-components/DevContainer/utils/apiOverrides/DevAPIOverrideController';
import { LogWarning } from './logging';
import configs from '../configs';
import {
  getStoredQueryParams,
  getUserSessionId,
  getPageViewId,
} from './analyticsHelpers';
import isBrowser from './isBrowser';
import { isSessionStorageEnabled } from './generalUtils';

// TODO: [T1-9385] import DevAPIOverrideController dynamically only if on dev
const apiOverrides = getDevAPIOverrideController();

const MEMORY_CACHE = {};

/**
 *
 * @param {String} args.method - either get or post
 * @param {String} args.url - the endpoint you are looking to hit
 * @param {Object} args.body - the request body
 * @param {Object} args.query - url params to send
 * @param {Boolean} args.shouldRetry - Deprecated use with retryablePromise
 * @param {String} args.contentType - content type to accept
 * @param {Boolean} args.isWP - is this a request to our WP server, can only be done server side
 * @param {Boolean} args.getRawRes - if true will return the full res obj
 * @param {String} args.cacheKey - if set then will cache results in session storage
 * @param {String} args.responseTimeout - how long to wait for the server to start sending
 * @param {String} args.deadlineTimeout - how long to wait for the file to finish loading
 */
function request({
  method = 'get',
  url,
  body,
  query,
  contentType,
  isWP = false,
  getRawRes = false,
  headers,
  cacheKey,
  useMemoryCache = false,
  responseTimeout,
  deadlineTimeout,
}) {
  const req = superagent[method](isWP ? `${configs.WPHost}${url}` : url);

  isWP && req.set('Host', configs.WPDomain);
  // TODO: Attach incoming browser user agent here
  isWP && req.set('User-Agent', 'node');
  headers && req.set(headers);
  body && req.send(body);
  query && req.query(query);

  // All Api on the browser will send sessionId and params
  const isTriadRequest = isBrowser() && url.includes(TRIAD_PROXY_ROUTE);
  if (isTriadRequest && method === 'post') {
    req.send({
      sessionId: getUserSessionId(),
      pageViewId: getPageViewId(),
      queryParams: getStoredQueryParams().requestArray,
    });
  } else if (isTriadRequest && method === 'get') {
    req.query({
      sessionId: getUserSessionId(),
      pageViewId: getPageViewId(),
    });
  }

  // generate traceId and pass it in all requests. If on server take it from request
  const uuid = get(
    body || query,
    'requestUUID',
    `${Date.now()}_${Math.random()}`
  );

  // On Browser attached extra headers/data to request
  if (isBrowser()) {
    body && req.send({ requestUUID: uuid });
    query && req.query({ requestUUID: uuid });

    if (apiOverrides.isActive && apiOverrides.isApiOverride(url)) {
      return apiOverrides.callApiOverride(url, { body, query });
    }
  }

  if (cacheKey && isBrowser() && useMemoryCache) {
    const cachedValue = MEMORY_CACHE[cacheKey];
    if (cachedValue) {
      console.log(`Cached value found for ${cacheKey}`);
      return Promise.resolve(JSON.parse(cachedValue));
    }
  }

  if (cacheKey && isBrowser() && isSessionStorageEnabled() && !useMemoryCache) {
    const cachedValue = sessionStorage.getItem(cacheKey);
    if (cachedValue) {
      console.log(`Cached value found for ${cacheKey}`);
      return Promise.resolve(JSON.parse(cachedValue));
    }
  }

  // On Server attached extra headers/data to request
  if (!isBrowser()) {
    // always attach auth token to server requests
    if (url.includes(TRIADMS_BACKEND)) {
      configs.triadApiAuth && req.set({ Authorization: configs.triadApiAuth });
    }
  }

  return req
    .accept(contentType || 'application/json')
    .timeout({
      response: responseTimeout || 20000, // Wait 20 seconds for the server to start sending,
      deadline: deadlineTimeout || 30000, // but allow 30 seconds minute for the file to finish loading.
    })
    .then((res) => {
      const results = getRawRes ? res : res.body;
      // base request function will only cache on browsers where a cacheKey is passed in and the response from backend has not told us to exclude from cache
      if (cacheKey && isBrowser() && res.body.Cache !== false) {
        try {
          if (useMemoryCache) {
            MEMORY_CACHE[cacheKey] = JSON.stringify({ ...results, cacheKey });
          } else {
            sessionStorage.setItem(
              cacheKey,
              JSON.stringify({ ...results, cacheKey })
            );
          }
        } catch (error) {
          LogWarning(`Failed to set API results in session storage`, {
            description: error.message,
            endpoint: url,
          });
        }
      }
      return results;
    })
    .catch((err) => {
      LogWarning(`${isBrowser() ? 'Browser' : 'Server'} API call Failed`, {
        description: err.message,
        endpoint: url,
      });
      throw err;
    });
}

export default request;
