import get from 'lodash/get';
import { LogError, newrelicPageAction } from 'utils/logging';
import request from 'utils/request';
import requestPropsTransform from 'utils/wordpressPropsTransform';
import {
  TRIADMS_BACKEND,
  TRIAD_PROXY_ROUTE,
  WP_API_URL,
} from 'app-requests/apiConstants';
import {
  formValuesToRequestArr,
  getDOMFieldValue,
  isValidZip,
  questionsToMap,
} from 'utils/formValuesUtils';
import isBrowser from 'utils/isBrowser';
import {
  appendDataLayerValue,
  trackGAConversion,
  trackFormSubmit,
} from 'utils/trackingFunctions';
import {
  getAllCookies,
  getPageViewId,
  getStoredQueryParams,
  getUserSessionId,
} from 'utils/analyticsHelpers';
import { QUERY_PARAMS, QUESTION_IDS } from 'consts';
import { appendGAValue } from 'utils/thirdPartyScripts';
import {
  isQuestionOptionsCacheable,
  retryablePromise,
} from 'utils/generalUtils';
import { getSchoolLogoUrlMap } from 'utils/imageHelpers';
import { COUNTERS, incrementCounter } from 'utils/counters';
import parseWpQuestionnaires from './transformers/parseWpQuestionnaires';

const { SHARED_EDU_ID, SHARED_SESSION_ID } = QUERY_PARAMS;

const { SUBJECT_0F_INTEREST, MICRO_PORTAL_DYNAMIC_TCPA } = QUESTION_IDS;

/**
 * @summary this is used to get all questions in the question bank
 */
export async function getQuestionBank(redisHelpers) {
  try {
    const path = isBrowser() ? TRIAD_PROXY_ROUTE : TRIADMS_BACKEND;

    if (!isBrowser()) {
      const bank = await redisHelpers.getQuestionBank();
      if (bank) {
        return Promise.resolve(bank);
      }
    }

    const { questionBank } = await request({
      method: 'get',
      url: `${path}/questionBank`,
    });
    const parsedBank = questionsToMap(questionBank);

    if (!isBrowser()) {
      redisHelpers.setQuestionBank(parsedBank);
    }

    return parsedBank;
  } catch (error) {
    LogError(`getQuestionBank Errors: ${error.message}`);
    throw error;
  }
}

/**
 * @summary this is used to get the questionnaire for a micro site
 * @param {String} domain - the domain of this microsite
 * @param {String} variant - the AB test variant to use
 * @param {Object} questionBankMap - All Questions in TriadSystem
 */
export async function getQuestionnaire({
  domain,
  variant,
  schoolCode,
  questionBankMap,
  redisHelpers,
}) {
  if (!schoolCode) {
    LogError('getQuestionnaire: No School Code Found in Wordpress Configs', {
      domain,
      variant,
    });
  }

  if (!isBrowser()) {
    const questionnaire = await redisHelpers.getQuestionnaire(variant);
    if (questionnaire) {
      return Promise.resolve(questionnaire);
    }
  }

  return request({
    isWP: true,
    url: `${WP_API_URL}/triad_questionnaire/${variant}`,
    query: {
      frontendDomain: domain,
    },
  })
    .then((questionnaire) => {
      // log error if requested questionnaire doesn't match the loaded questionnaire

      if (
        variant &&
        questionnaire.questionnaireSlug !== variant &&
        questionnaire.questionnaireId !== variant
      ) {
        LogError(
          `getQuestionnaire Errors: Requested questionnaire ID ${variant} and received ID ${questionnaire.questionnaireId}`,
          {
            domain,
            variant,
          }
        );
      }
      const output = requestPropsTransform(questionnaire, {
        questionBankMap,
        schoolCode,
        variant: get(questionnaire, 'questionnaireName'),
      });

      if (!isBrowser()) {
        redisHelpers.setQuestionnaire(variant, output);
      }

      return output;
    })
    .catch((error) => {
      LogError(`getQuestionnaire Errors: ${error.message}`, {
        domain,
        variant,
      });
      throw error;
    });
}

/**
 * @summary this is used to get the questionnaire for a micro site
 * @param {String} domain - the domain of this microsite
 * @param {String} variant - the AB test variant to use
 * @param {Object} questionBankMap - All Questions in TriadSystem
 */
export async function getQuestionnaires(args) {
  const { domain, schoolCode, redisHelpers } = args;
  if (!schoolCode || !domain) {
    LogError('getQuestionnaires: No School Code Found in Wordpress Configs', {
      domain,
    });
  }

  const questionnaires = await redisHelpers.getQuestionnaires();
  if (questionnaires) {
    return Promise.resolve(questionnaires);
  }

  return request({
    isWP: true,
    url: `${WP_API_URL}/triad_questionnaire`,
    query: {
      frontendDomain: domain,
    },
  })
    .then((wpQuestionnaires) => {
      const parsedResponse = parseWpQuestionnaires(wpQuestionnaires, args);
      redisHelpers.setQuestionnaires(parsedResponse);
      return parsedResponse;
    })
    .catch((error) => {
      LogError(`getQuestionnaire Errors: ${error.message}`, {
        domain,
      });
      throw error;
    });
}

/**
 * @summary use this to log current progress for user's session so we can get it back in the getProfile API
 * @param {Object} formValues - current form state
 * @param {Object} fieldNameMap - map of all field names
 * @param {String} config.schoolCode - this comes from wordpress to tell us the code for this school
 * @param {String} config.variant - the questionnaire version we are showing user, A/B test
 * @param {Object} linkedSessionFormValues - form values that could have come from another session
 * @param {number} lastQuestionAnswered - id of the last question answered
 * @param {?String=} endpoint - the endpoint to hit
 */
let didAlert = false;
function _logProgress(
  formValues,
  fieldNameMap,
  { schoolCode, variant },
  linkedSessionFormValues,
  lastQuestionAnswered,
  endpoint = '/questionLog'
) {
  const userSessionId = getUserSessionId();
  const { queryParamMap } = getStoredQueryParams();

  if (!userSessionId && !didAlert) {
    didAlert = true;
    LogError('No Session Cookie Found When Logging Question', {
      schoolCode,
      variant,
      formAnsweredQuestion: Object.keys(formValues).join(', '),
    });
    return Promise.resolve({});
  }

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}${endpoint}`,
    body: {
      schoolCode,
      templateName: variant,
      questionReplies: formValuesToRequestArr(
        formValues,
        fieldNameMap,
        false,
        linkedSessionFormValues
      ),
      currentQuestionId: lastQuestionAnswered,
      [SHARED_EDU_ID]: queryParamMap[SHARED_EDU_ID],
      [SHARED_SESSION_ID]: queryParamMap[SHARED_SESSION_ID],
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // We do not want to throw error. Let the user continue on
      return error;
    });
}

/**
 * @summary a proxy function for _logProgress so that we may call the last one
 * @param  {...any} args - see above function _logProgress
 */
let logProgressInterval = null;
export function logProgress(...args) {
  clearInterval(logProgressInterval);

  // if we have a user session then just call the API
  if (getUserSessionId()) {
    return _logProgress(...args);
  }

  logProgressInterval = setInterval(() => {
    if (getUserSessionId()) {
      clearInterval(logProgressInterval);
      _logProgress(...args);
    }
  }, 1500);

  return Promise.resolve({});
}

/**
 * @summary given the current state of the form and the current school we may need to get options for a question
 * @param {Array} payload.requestedOptions List of options we want for questions
 * @param {*} questionReplies A map of questions and their answers
 * @param {String} variant - the questionnaire version we are showing user, A/B test
 * @param {?String=} endpoint - the endpoint to hit
 */
export function requestOptions(
  payload,
  schoolCode,
  variant,
  endpoint = '/questionOption'
) {
  const replies = get(payload, 'questionReplies', []).map(
    ({ questionAnswer, questionId }) => ({
      questionAnswer,
      questionId,
    })
  );

  const neededOptions = get(payload, 'requestedOptions', []);
  const endpointUrl = `${TRIAD_PROXY_ROUTE}${endpoint}`;
  let cacheKey = '';

  // if options request is just for subject then cache
  if (isQuestionOptionsCacheable(neededOptions)) {
    // not taking variant into account here because we are only caching for subject which is the same on all variants
    cacheKey = `${schoolCode}_questionOptions_${SUBJECT_0F_INTEREST}`;
  }

  // BUG: [T1-9320] If API returns 0 results we should not cache.
  return request({
    method: 'post',
    url: endpointUrl,
    cacheKey,
    body: {
      schoolCode,
      templateName: variant,
      requestedOptions: neededOptions,
      questionReplies: replies,
    },
  })
    .then(({ questions, autoSelectedSchools, remainingSchoolsCount }) => {
      if (autoSelectedSchools) {
        return {
          [MICRO_PORTAL_DYNAMIC_TCPA]: {
            remainingSchoolsCount,
            questionId: MICRO_PORTAL_DYNAMIC_TCPA,
            options: autoSelectedSchools.map(
              ({ schoolImpressionGuid, schoolName }) => ({
                label: schoolName,
                value: schoolImpressionGuid,
              })
            ),
          },
        };
      }

      return questionsToMap(questions);
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * @summary used to track needed session ids for google to track offsite conversions
 */
let isCurrentSessionTracked = false;

export function forTestingOnlyClearIsCurrentSessionTracked() {
  isCurrentSessionTracked = false;
}

export function getUsersGoogleSessionIds() {
  const cookies = getAllCookies();
  return cookies.keys
    .filter((key) => key.match(/^(_ga|_gcl_)/))
    .reduce((keyValueArray, key) => {
      keyValueArray.push({
        name: key,
        value: cookies.map[key],
      });

      return keyValueArray;
    }, []);
}

export function trackUsersGoogleSessionIds() {
  if (isCurrentSessionTracked) {
    return Promise.resolve();
  }

  isCurrentSessionTracked = true;

  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/SetUserMetadata`,
    body: {
      metadata: getUsersGoogleSessionIds(),
    },
  });
}

/**
 * @summary This function is used to submit the lead
 * @param {Object} formValues - current form state
 * @param {Object} fieldNameMap - map of all field names
 * @param {String} config.schoolCode - this comes from wordpress to tell us the code for this school
 * @param {String} config.variant - the questionnaire version we are showing user, A/B test
 * @param {?String=} config.leadSubmitEndpoint - the endpoint to hit
 * @param {?String} config.disclaimerText - the disclaimer text the user sees
 */
export async function submitLead(
  formValues,
  fieldNameMap,
  formConfigs,
  metaData = {}
) {
  const { isClickUser } = metaData;
  const {
    schoolCode,
    variant,
    leadSubmitEndpoint = '/leadSubmit',
    disclaimerText,
    leadEvalToken,
  } = formConfigs;

  // TODO: look into this as GA shows a very large number compared to profile payable user events
  trackFormSubmit();

  // TODO: once backend is live with Conversion Tracking there is refactor needed so frontend does not send conversion events
  const metadata = getUsersGoogleSessionIds();

  if (!getUserSessionId() || !getPageViewId()) {
    LogError('No UserSessionId or PageViewId Found when submitting form', {
      sessionId: getUserSessionId(),
      pageViewId: getPageViewId(),
    });
    incrementCounter(COUNTERS.NO_SESSION_SUBMIT);
  }

  newrelicPageAction('lead_submit', { requestStatus: 'started' });
  try {
    const {
      status,
      result = {},
      SubmittedResponses,
      queue,
      BatchId,
    } = await retryablePromise(() =>
      request({
        method: 'post',
        // Setting to 120 seconds. Lead submit can take a long time and we should not timeout.
        // Cloudflare will timeout after 100 seconds
        responseTimeout: 120000,
        deadlineTimeout: 120000,
        url: `${TRIAD_PROXY_ROUTE}${leadSubmitEndpoint}`,
        body: {
          schoolCode,
          templateName: variant,
          tcpaText: disclaimerText,
          trustedFormUrl: getDOMFieldValue('xxTrustedFormCertUrl_0'),
          leadId: getDOMFieldValue('leadid_token'),
          questionReplies: formValuesToRequestArr(formValues, fieldNameMap),
          isClickUser,
          metadata,
          leadEvalToken,
        },
      })
    );

    if (queue) {
      return {
        leadsSubmittedFor: queue.map((school) => ({
          impressionGuid: school.ImpressionGuid,
          schoolCode: school.SchoolCode,
          transactionId: school.TransactionId,
        })),
        transactionStatus: status,
        batchTransactionId: BatchId,
        shouldTrackConversion: false,
      };
    }

    if (SubmittedResponses) {
      // For more the one lead submit, mainly micro portal
      return {
        shouldTrackConversion: false,
        leadsSubmittedFor: SubmittedResponses.map((school) => ({
          leadGuid: school.result.LeadGuid,
          statusMessage: school.result.Message,
          status: school.result.Reason,
          revenue: school.result.Revenue,
          schoolCode: school.result.SchoolCode,
          name: school.result.SchoolName,
          schoolLogoUrl: getSchoolLogoUrlMap(school?.result?.SchoolImages),
          rating: school.result.SchoolRating,
        })),
      };
    }

    // For a single lead submit, mainly micro sites
    appendDataLayerValue('profileId', result.ProfileId ?? 'NOT_FOUND');

    const isTestMode = result.Message?.includes('TEST MODE;');
    const isAccepted = result.Message?.startsWith('ACCEPTED');
    const isPending = result.Message?.includes('PENDING');
    const shouldTrackConversion = status === 'ok' && isAccepted && !isTestMode;

    if (shouldTrackConversion) {
      newrelicPageAction('lead_submit', {
        requestStatus: isPending ? 'success-pending' : 'success-accepted',
        isTestMode: isTestMode ? 'true' : 'false',
      });
    } else {
      newrelicPageAction('lead_submit', {
        requestStatus: 'success-rejected',
        isTestMode: isTestMode ? 'true' : 'false',
      });
    }

    return {
      shouldTrackConversion,
      leadsSubmittedFor: [
        {
          leadGuid: result.ProfileId,
          statusMessage: result.Message,
          status,
          revenue: result.Revenue,
          adjustedRevenue: result.AdjustedRevenue,
          schoolCode,
        },
      ],
    };
  } catch (error) {
    newrelicPageAction('lead_submit', { requestStatus: 'failed' });
    LogError(`submitLead ${error.message}`, {
      trace: error.trace,
      endpoint: leadSubmitEndpoint,
    });
    throw error;
  }
}

/**
 * @summary used to validate the user's zip code
 * @param {String} args.zip - zip code
 */
const browserZipPromiseCache = {};
export function getDataForZip({ zip }) {
  if (browserZipPromiseCache[zip]) {
    return browserZipPromiseCache[zip];
  }

  if (!isValidZip(zip)) {
    return Promise.resolve({ isValid: false });
  }

  browserZipPromiseCache[zip] = request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/zipCheck`,
    body: {
      zipCode: zip,
    },
  })
    .then((res) => {
      return {
        ...res,
        isValid: !!(res.state && res.city && zip === res.zipCode),
        suggestion: zip !== res.zipCode ? res.zipCode : '',
      };
    })
    .catch(() => {
      return { isValid: true };
    });

  return browserZipPromiseCache[zip];
}

/**
 * @summary builds the url and parameters that make up the get request to retrieve
 * a new session id from the backend
 */
export function buildGenerateUserSessionIdRequest(queryParams, siteMeta = {}) {
  const requestPayload = {
    deadlineTimeout: 5000,
    responseTimeout: 5000,
    url: `${TRIAD_PROXY_ROUTE}/GetNewSession`,
    query: {
      tmsurl: window.location.pathname,
      tmshost: window.location.host,
      tmsaq: 1,
      schoolCode: siteMeta.schoolCode,
    },
  };

  if (queryParams) {
    Object.keys(queryParams).forEach((param) => {
      requestPayload.query[param] = queryParams[param];
    });
  }

  return requestPayload;
}

/**
 * @summary used to get a new user session token if not in a cookie value
 */
export function generateUserSessionId(queryParams, siteMeta) {
  const sessionRequest = buildGenerateUserSessionIdRequest(
    queryParams,
    siteMeta
  );
  const sessionIdRequest = () =>
    request(sessionRequest).then((sessionResponse) => {
      const {
        SessionId,
        PageViewId,
        trace,
        spc,
        ppc,
        propertyOrigin,
        campaignType,
        trackingSchoolCode,
        isClickUser = false,
        adPlatformSource,
      } = sessionResponse;

      spc && appendGAValue('spc', spc);
      ppc && appendGAValue('ppc', ppc);

      if (SessionId === '00000000-0000-0000-0000-000000000000') {
        LogError('Session Error: token not unique', {
          trace,
        });
      }

      if (!SessionId) {
        LogError('Session Error: empty sessionId received', {
          trace,
          pageViewId: PageViewId,
        });
      }

      return {
        sessionId: SessionId,
        pageViewId: PageViewId,
        // TODO: [T1-11350] Rename from floodLightActivityFilters to userAttributionFilters
        floodLightActivityFilters: {
          trackingSchoolCode,
          propertyOrigin,
          campaignType,
          isClickUser,
          adPlatformSource,
        },
      };
    });

  return retryablePromise(sessionIdRequest, {
    maxRetryAttempts: 5,
    waitTimeBetweenFails: 2000,
  }).catch((error) => {
    throw new Error('Get Session API Frontend API call failed', {
      trace: get(error, 'response.body.trace'),
    });
  });
}

/**
 * @summary function to run to validate a given phone number
 */
const browserPhonePromiseCache = {};
export function validatePhone(phone) {
  if (browserPhonePromiseCache[phone]) {
    return browserPhonePromiseCache[phone];
  }

  browserPhonePromiseCache[phone] = request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/npaNxxCheck`,
    body: {
      phoneNumber: phone,
    },
  }).then(({ phoneNumber }) => {
    const validationResults = { isValid: !!phoneNumber };

    return validationResults;
  });

  return browserPhonePromiseCache[phone];
}

/**
 * @summary Use this to get a user's profile info
 * @param {Array} taxonomyValues.degrees - the degrees associated with the current page
 * @param {Array} taxonomyValues.parentCategories - the parentCategories associated with the current page
 * @param {Array} taxonomyValues.categories - the categories associated with the current page
 */
export function getUserProfile(taxonomyValues) {
  const parentCategoryGuid = taxonomyValues?.parentCategories[0];
  const { queryParamMap } = getStoredQueryParams();
  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/userProfile`,
    body: {
      parentCategoryGuid,
      [SHARED_EDU_ID]: queryParamMap[SHARED_EDU_ID],
      [SHARED_SESSION_ID]: queryParamMap[SHARED_SESSION_ID],
      includeGeoLocation: false,
    },
  });
}

export function extractAndSortTaxonomy(children, level = 1) {
  return children.map((child) => ({
    level,
    orderBy: child.orderBy || null,
    value: child.value,
    label: child.label,
    children: extractAndSortTaxonomy(
      child.parentCategories || child.categories || [],
      level + 1
    ),
  }));
}

/**
 * @summary this will get the navigation for the Click Portal
 */
export async function getProgramTaxonomy() {
  return request({
    method: 'get',
    url: `${TRIADMS_BACKEND}/ClickPortal/GetProgramTaxonomy`,
  }).then((res) => {
    if (!res.IsValid) {
      LogError(res.Error[0]);
      throw new Error(res.Error[0]);
    }

    return {
      degrees: extractAndSortTaxonomy(res.degreeTypes),
    };
  });
}

function parseSchoolDescription(desc) {
  if (!desc) {
    return 'Description not available please check back soon.';
  }

  return desc.map((txt) => `<p>${txt}</p>`).join();
}

function getMicroPortalPostLeadSubmitAdditionalSchoolResults() {
  return request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/microportal/GetMPAdditionalSchoolListings`,
  }).then(({ Listings, PhoneNumber }) => {
    return {
      phoneNumber: PhoneNumber,
      listings: Listings?.map((result) => {
        return {
          schoolGuid: result.value,
          schoolCode: result.schoolCode,
          schoolName: result.schoolName,
          impressionGuid: result.impressionGuid,
          label: result.label,
          rating: result.rating,
          description: parseSchoolDescription(result.schoolDesc),
          programs: result.programOptions[0]?.options,
          schoolLogo: getSchoolLogoUrlMap(result.schoolImages),
        };
      }),
    };
  });
}

export async function getMicroPortalPostLeadSubmitQuestionnaire({
  schoolCode,
}) {
  const schoolResults =
    await getMicroPortalPostLeadSubmitAdditionalSchoolResults();

  const questions = schoolResults.listings.map((school) => {
    return {
      grouping: 1,
      fieldGroupName: 'additionalResults',
      // TODO: [T1-10233] we should clean up core form logic to only need questionId or id and normalize the parsed backend question bank response respectively
      questionId: school.schoolGuid,
      id: school.schoolGuid,
      label: school.label,
      name: school?.schoolGuid?.toString(),
      type: 'SELECTION_CARD',
      required: false,
      isPii: false,
      options: school.programs,
      dependency: {},
      meta: {
        description: school.description,
        rating: school.rating,
        schoolLogo: school.schoolLogo,
        schoolName: school.schoolName,
        impressionGuid: school.impressionGuid,
      },
    };
  });

  questions.push({
    grouping: 1,
    questionId: QUESTION_IDS.MICRO_PORTAL_DYNAMIC_TCPA,
    id: QUESTION_IDS.MICRO_PORTAL_DYNAMIC_TCPA,
    label: 'second_page_tcpa',
    name: 'second_page_tcpa',
    type: 'ADDITIONAL_SCHOOLS_DISCLAIMER',
    required: true,
    isPii: false,
    options: [],
    meta: { phoneNumber: schoolResults?.phoneNumber },
  });

  return {
    schoolCode,
    variant: null,
    id: 'microPortalQuestionnaire',
    subTitle: null,
    title: null,
    stepsCount: 1,

    steps: [
      {
        questions,
        id: 1,
        trueStepIndex: 0,
        title: null,
        subTitle: null,
        heading: null,
        progressMeter: null,
        groupLabel: 'You Might Also Be Interested In',
      },
    ],
  };
}

export async function getApplicationLinks({
  sessionId,
  schoolCode,
  pageViewId,
}) {
  try {
    const response = await request({
      method: 'post',
      url: `${TRIAD_PROXY_ROUTE}/thankyoulinks`,
      body: {
        sessionId,
        schoolCode,
        pageViewId,
      },
    });

    return {
      applicationLink: response?.Links?.ApplicationLink,
    };
  } catch (error) {
    LogError(`Error fetching application links: ${error.message}`);
    return '';
  }
}

/**
 * @param {String} args.transactionId - the transaction id for the lead submit request
 * @returns {Promise} - the response from the lead submit results endpoint
 */
const CONVERSIONS_TRACKED = {};
export async function getMicroPortalLeadSubmitResults({ transactionId }) {
  const { SchoolLeadResponses, Summary } = await request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/GetLeadSubmitQueue`,
    body: {
      BatchId: transactionId,
    },
  });

  // Note we do not need to check for test mode because backend will set IsFirePixel to false
  const shouldTrackConversion =
    Summary.IsFirePixel && !CONVERSIONS_TRACKED[transactionId];
  if (shouldTrackConversion) {
    trackGAConversion(Summary.Revenue ?? 0, Summary.AdjustedRevenue ?? 0);
    CONVERSIONS_TRACKED[transactionId] = true;
    appendDataLayerValue('profileId', Summary.ProfileId ?? 'NOT_FOUND');
  }

  return {
    shouldTrackConversion,
    summary: {
      revenue: Summary.Revenue,
      adjustedRevenue: Summary.AdjustedRevenue,
    },
    leadsSubmittedFor: SchoolLeadResponses.map((school) => {
      return {
        leadGuid: school.TransactionId,
        leadStatus: school.LeadStatus,
        name: school.SchoolName,
        schoolLogoUrl: getSchoolLogoUrlMap(school.SchoolImages),
        rating: school.SchoolRating,
      };
    }).filter(({ leadStatus }) => leadStatus === 'ACCEPTED'),
    isWaitMoreResults: SchoolLeadResponses.some(({ TaskStatus }) => {
      return TaskStatus === 'UNKNOWN';
    }),
  };
}

/**
 * Transforms a backend tracking tag object into a more structured format.
 *
 * @param {Object} tag - The backend tracking tag object to be transformed.
 * @param {string} tag.adPlatformSource - The source of the ad platform.
 * @param {string} tag.campaignType - The type of the campaign.
 * @param {string} tag.eventType - The type of the event.
 * @param {boolean} tag.isClickUser - Indicates if the user is a click user.
 * @param {boolean} tag.isConversion - Indicates if it is a conversion.
 * @param {string} tag.propertyOrigin - The origin of the property.
 * @param {string} tag.tagDestination - The destination of the tag.
 * @param {string} tag.trackingSchoolCode - The code of the tracking school.
 * @param {string} tag.gadAdvertiserId - The ID of the Google Advertiser.
 * @param {string} tag.gadGroupTagString - The group tag string for Google Ads.
 * @param {string} tag.gadActivityTagString - The activity tag string for Google Ads.
 * @param {string} tag.msaCategory - The category for Microsoft Advertising.
 * @param {string} tag.msaAction - The action for Microsoft Advertising.
 * @param {string} tag.msaLabel - The label for Microsoft Advertising.
 * @param {number} tag.msaValue - The value for Microsoft Advertising.
 * @param {string} tag.msaUetTagID - The UET tag ID for Microsoft Advertising.
 * @return {Object} - The transformed tracking tag object.
 */
function transformBackendTrackingTag(backendTag) {
  return {
    // Values that frontend needs for mapping
    mapKeys: {
      // These are hardcoded on frontend. Can be google or microsoft
      adPlatformDestination: backendTag.tagDestination || '',
      // boolean value
      isConversion: backendTag.isConversion ?? null,
      // These are hardcoded on frontend. values like Conversion_Original_Lead_Revenue or Conversion_Adjusted_Lead_Revenue.
      eventType: backendTag.eventType || '',
    },
    // Only values that come in user session should be included. These are the only filters we are applying
    filters: {
      adPlatformSource: backendTag.adPlatformSource || '',
      campaignType: backendTag.campaignType || '',
      isClickUser: backendTag.isClickUser ?? null,
      propertyOrigin: backendTag.propertyOrigin || '',
      trackingSchoolCode: backendTag.trackingSchoolCode || '',
    },
    // Only values that we need to send to google
    google: {
      advertiserId: backendTag.gadAdvertiserId || '',
      groupTagString: backendTag.gadGroupTagString || '',
      activityTagString: backendTag.gadActivityTagString || '',
    },
    // Only values that we need to send to microsoft
    microsoft: {
      category: backendTag.msaCategory || '',
      action: backendTag.msaAction || '',
      label: backendTag.msaLabel || '',
      value: backendTag.msaValue || '',
      uetTagId: backendTag.msaUetTagID || '',
    },
  };
}

/*
 * @param {String} schoolCode - the school code for the current school
 */
// TODO: [T1-11350] Rename from fetchFloodlightActivityValues to fetchAvailableAttributionValues
export function fetchFloodlightActivityValues(schoolCode) {
  if (!schoolCode) {
    LogError('fetchFloodlightActivityValues: No School Code');
    return Promise.resolve({});
  }

  return request({
    method: 'post',
    url: `${TRIADMS_BACKEND}/GetCampaignManagerActivityValues`,
    body: {
      schoolCode,
    },
  })
    .then((res) => {
      return {
        eventValues: res.tagEvents.map(transformBackendTrackingTag) || [],
      };
    })
    .catch((error) => {
      LogError(`fetchFloodlightActivityValues: ${error.message}`);
      return {
        eventValues: [],
      };
    });
}

export async function getLeadEvalToken(
  formValues,
  fieldNameMap,
  schoolCode,
  metaData = {}
) {
  const { isClickUser } = metaData;

  const response = await request({
    method: 'post',
    url: `${TRIAD_PROXY_ROUTE}/getLeadEvalToken`,
    cacheKey: JSON.stringify(formValues),
    useMemoryCache: true,
    body: {
      schoolCode,
      trustedFormUrl: getDOMFieldValue('xxTrustedFormCertUrl_0'),
      leadId: getDOMFieldValue('leadid_token'),
      questionReplies: formValuesToRequestArr(formValues, fieldNameMap),
      isClickUser,
    },
  });

  if (!response.leadEvalToken) {
    throw new Error('No lead eval token returned');
  }

  return { leadEvalToken: response.leadEvalToken };
}
