import { initialState } from 'hooks/reducers/GlobalReducer';
import React from 'react';

const globalContext = {
  sessionInfo: initialState.sessionInfo,
  questionnaire: {},
  taxonomyValues: {
    degrees: [],
    parentCategories: [],
    categories: [],
  },
  flags: initialState.flags,
  userData: initialState.userData,
  refs: { heroRef: null, headerRef: null, modalContainerRef: null },
  actions: {},
  windowSize: initialState.windowSize,
  clickPortal: initialState.clickPortal,
  formSettings: initialState.formSettings,
  microPortal: initialState.microPortal,
};

const GlobalContext = React.createContext(globalContext);

export default GlobalContext;
